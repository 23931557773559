import { useCallback, useState } from 'react';
import StandardModal from '../../shared/modal/variants/StandardModal';
import { useCreateDocumentWizard } from '../../../contexts/CreateDocumentContext';
import { FileInfo } from '../../shared/file-upload/FileInfo';
import { useTranslation } from 'react-i18next';
import FileService from '../../../services/FileService';
import UploadFile from '../UploadFile';

const UploadStep = () => {
  const { nextStep, newDocument, setNewDocument } = useCreateDocumentWizard();
  const [fileUploadProgress, setFileUploadProgress] = useState(newDocument.file ? 1 : 0);
  const { t } = useTranslation(['documents']);

  const deleteFile = useCallback(() => {
    setFileUploadProgress(0);
    if (newDocument.fileId) {
      return FileService.deleteFile(newDocument.fileId).then(() => {
        setNewDocument && setNewDocument((prev) => ({ ...prev, fileId: undefined, file: undefined, subtitle: '' }));
      });
    }
    return Promise.resolve();
  }, [newDocument.fileId, setNewDocument]);

  const onUpload = useCallback(
    async (fi: FileInfo[]) => {
      if (newDocument.file) {
        await deleteFile();
      }

      let fileName = fi[0]!.name;
      fileName = fileName.slice(0, fileName.lastIndexOf('.'));
      FileService.uploadFile(fi[0].file, setFileUploadProgress).then((res) => {
        setNewDocument && setNewDocument((prev) => ({ ...prev, fileId: res.data[0].id, file: fi[0], subtitle: fileName }));
      });
    },
    [deleteFile, newDocument.file, setNewDocument],
  );

  return (
    <StandardModal
      title={t('create-doc-wizard.file-upload.title')}
      confirmButtonTitle={t('create-doc-wizard.buttons.continue')}
      confirmDisabled={!newDocument.file}
      onConfirmClick={() => nextStep && nextStep('detailStep')}
      tertiaryButtonTitle={t('replace-doc-wizard.buttons.choose-template')}
      onTertiaryButtonClick={() => nextStep && nextStep('fromTemplate')}
      tertiaryButtonIcon={null}
    >
      <div className="flex h-[60vh] flex-grow flex-col items-center overflow-auto px-1 text-center ">
        <div className="h-[55vh] w-full">
          <UploadFile file={newDocument.file} deleteFile={deleteFile} onUpload={onUpload} fileUploadProgress={fileUploadProgress} />
        </div>
      </div>
    </StandardModal>
  );
};

export default UploadStep;
